<template>
  <div class="pt-10">
    <div class="mb-10">

      <div class="row mb-3" v-show="success">
        <div class="col-12">
          <div class="alert alert-success" role="alert">
            Successfully updated production control!
          </div>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-12 mb-3 col-lg-6 mb-lg-0">
          <label class="col-form-label required fw-bold fs-6">Order Date</label>
          <input type="date" class="form-control" v-model="store.getters.myLotAssembly.orderDate" :disabled="true">
        </div>
        <div class="col-12 mb-3 col-lg-6 mb-lg-0">
          <label class="col-form-label required fw-bold fs-6">Quantity</label>
          <input type="number" class="form-control" :class="{'is-invalid' : errors.value && errors.value.quantity}" v-model="store.getters.myLotAssembly.quantity" :disabled="true">
          <span class="text-danger" v-if="errors.value && errors.value.quantity">{{errors.value && errors.value.quantity[0]}}</span>
        </div>

      </div>

      <div class="row mb-6">
        <div class="col-12 mb-3 col-lg-6 mb-lg-0">
          <label class="col-form-label fw-bold fs-6">Sealing Machine</label>
          <input type="text" class="form-control" :class="{'is-invalid' : errors.value && errors.value.sealingMachineNumber}" v-model="store.getters.myLotAssembly.sealingMachineNumber">
          <span class="text-danger" v-if="errors.value && errors.value.sealingMachineNumber">{{errors.value && errors.value.sealingMachineNumber[0]}}</span>
        </div>
        <div class="col-12 mb-3 col-lg-6 mb-lg-0">
          <label class="col-form-label required fw-bold fs-6">Prepared By</label>
          <select class="form-select" :class="{'is-invalid' : errors.value && errors.value.user_id}" v-model="store.getters.myLotAssembly.user_id">
            <option selected disabled value="null">Select Please</option>
            <option :value="item.id" v-for="(item,key) in store.getters.myUsers" :key="key">{{item.first_name+' '+item.last_name}}</option>
          </select>
          <span class="text-danger" v-if="errors.value && errors.value.user_id">{{errors.value && errors.value.user_id[0]}}</span>
        </div>
      </div>

      <div class="text-end">
        <button
          type="submit"
          ref="submitButton"
          class="btn btn-primary"
          @click="submitForm"
        >
          <span class="indicator-label">
            Save
          </span>

          <span class="indicator-progress">
            Please wait...
            <span
              class="spinner-border spinner-border-sm align-middle ms-2"
            ></span>
          </span>
        </button>
      </div>

    </div>


    <div>
      <Table :columns="columns" ref="table" :action="actions.GET_LOT_PRODUCTION_ACTIONS" :id="Number(props.id)" :searchEnabled="false" :showPerPageEnabled="false">

        <template v-slot:extra="slotProps">

          <div class="mb-5">

            <AddProductionAction :id="props.id" @input="slotProps.search()"/>

          </div>

        </template>

        <template v-slot:startTime="slotProps">
          <div>{{moment(new Date(slotProps.row.startTime)).format('DD MMM YYYY, H:mm')}}</div>
        </template>

        <template v-slot:endTime="slotProps">
          <div>{{moment(new Date(slotProps.row.endTime)).format('DD MMM YYYY, H:mm')}}</div>
        </template>

        <template v-slot:actions="slotProps">

          <div>

            <div class="btn-group" role="group">

              <EditProductionAction :productionAction="slotProps.row" @input="slotProps.search()" />

              <DeleteRecord :id="slotProps.row.id" :action="actions.DELETE_LOT_PRODUCTION_ACTION" :callback="slotProps.search"/>

            </div>

          </div>

        </template>

      </Table>
    </div>


  </div>
</template>
<script lang="ts">
import { onMounted, ref, reactive, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { Actions as LotProductionActions } from "@/store/enums/LotProductionEnums.ts";
import { Actions as ProductionActions } from "@/store/enums/ProductionActionsEnums.ts";
import {Actions as UserActions} from '@/store/enums/UserEnums.ts';
import Table from '@/components/custom/Table.vue';
import DeleteRecord from '@/components/custom/DeleteRecord.vue';
import AddProductionAction from '@/views/lots/composites/production/AddProductionAction.vue'
import EditProductionAction from '@/views/lots/composites/production/EditProductionAction.vue'
import moment from 'moment'
export default {
  name : 'CompositeLotProductionControl',
  components : {
    Table,
    DeleteRecord,
    AddProductionAction,
    EditProductionAction
  },
  props : ["id"],
  setup(props){

    const store = useStore();

    const route = useRoute();

    const success = ref(false)

    const errors = reactive({
      value : {}
    });

    const submitButton = ref<HTMLElement | null>(null);

    onMounted(() => {

      store.dispatch(ProductionActions.GET_PRODUCTION_ACTIONS);

      store.dispatch(UserActions.GET_USERS);

    })

    const submitForm = () => {

      submitButton.value?.setAttribute("data-kt-indicator", "on");

      store.dispatch(LotProductionActions.UPDATE_PRODUCTION_CONTROL,store.getters.myLotAssembly).then(() => {

        success.value = true;
        errors.value = [];
        submitButton.value?.removeAttribute("data-kt-indicator");
        window.scrollTo(0,0);

      }).catch((response) => {

        setTimeout(function(){
          const input = document.getElementsByClassName('is-invalid')[0] as HTMLElement
          input.focus();
        },100)

        errors.value = response.data.errors;

        submitButton.value?.removeAttribute("data-kt-indicator");

      })

    }

    const actions = computed(() => {
      return LotProductionActions;
    })



    const columns = [
      {name : 'action', title : 'Action', sortable : true, sort : null, searchable : false},
      {name : 'startTime', title : 'Start Time', sortable : true, sort : 'desc', searchable : false},
      {name : 'endTime', title : 'End Time', sortable : true, sort : null, searchable : false},
      {name : 'operator', title : 'Operator', sortable : true, sort : null, searchable : false},
      {name : 'quantity', title : 'Quantity', sortable : true, sort : null, searchable : false},
      {name : 'cartonQuantity', title : 'Carton Quantity', sortable : true, sort : null, searchable : false},
      {name : 'actions', title : 'Delete',sortable : false, sort : null, searchable : false}
    ]

    return {
      store,
      route,
      success,
      errors,
      submitForm,
      submitButton,
      actions,
      columns,
      props,
      moment
    }
  }
}
</script>
