<template>
  <div class="pt-10">
    <div class="mb-10">

      <div class="row mb-3" v-show="success">
        <div class="col-12">
          <div class="alert alert-success" role="alert">
            Successfully updated label control!
          </div>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-12 mb-3 col-lg-6 mb-lg-0">
          <label class="col-form-label required fw-bold fs-6">Labels Produced</label>
          <input type="number" class="form-control" :class="{'is-invalid' : errors.value && errors.value.labelsProduced}" v-model="store.getters.myLotAssembly.labelsProduced">
          <span class="text-danger" v-if="errors.value && errors.value.labelsProduced">{{errors.value && errors.value.labelsProduced[0]}}</span>
        </div>
      </div>

      <div class="text-end">
        <button
          type="submit"
          ref="submitButton"
          class="btn btn-primary"
          @click="submitForm"
        >
          <span class="indicator-label">
            Save
          </span>

          <span class="indicator-progress">
            Please wait...
            <span
              class="spinner-border spinner-border-sm align-middle ms-2"
            ></span>
          </span>
        </button>
      </div>

    </div>


    <div>
      <Table :columns="columns" ref="table" :action="actions.GET_LOT_LABEL_ACTIONS" :id="Number(props.id)" :searchEnabled="false" :showPerPageEnabled="false">

        <template v-slot:extra="slotProps">

          <div class="mb-5">

            <AddLabelAction :id="props.id" @input="slotProps.search()"/>

          </div>

        </template>

        <template v-slot:startTime="slotProps">
          <div>{{moment(new Date(slotProps.row.statusDate)).format('DD MMM YYYY')}}</div>
        </template>


        <template v-slot:actions="slotProps">

          <div>

            <div class="btn-group" role="group">

              <EditLabelAction :labelAction="slotProps.row" @input="slotProps.search()" />

              <DeleteRecord :id="slotProps.row.id" :action="actions.DELETE_LOT_LABEL_ACTION" :callback="slotProps.search"/>

            </div>

          </div>

        </template>

      </Table>
    </div>


  </div>
</template>
<script lang="ts">
import { onMounted, ref, reactive, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

import { Actions as LotLabelActions } from "@/store/enums/LotLabelsEnums.ts";
import { Actions as LabelActions } from "@/store/enums/LabelActionsEnums.ts";
import Table from '@/components/custom/Table.vue';
import DeleteRecord from '@/components/custom/DeleteRecord.vue';
import AddLabelAction from '@/views/lots/composites/labels/AddLabelAction.vue'
import EditLabelAction from '@/views/lots/composites/labels/EditLabelAction.vue'
import moment from 'moment'
export default {
  name : 'CompositeLotLabelControl',
  components : {
    Table,
    DeleteRecord,
    AddLabelAction,
    EditLabelAction
  },
  props : ["id"],
  setup(props){

    const store = useStore();

    const route = useRoute();

    const success = ref(false)

    const errors = reactive({
      value : {}
    });

    const submitButton = ref<HTMLElement | null>(null);

    onMounted(() => {

      store.dispatch(LabelActions.GET_LABEL_ACTIONS);



    })

    const submitForm = () => {

      submitButton.value?.setAttribute("data-kt-indicator", "on");

      store.dispatch(LotLabelActions.UPDATE_LABEL_CONTROL,{id : props.id ,labelsProduced : store.getters.myLotAssembly.labelsProduced}).then(() => {

        success.value = true;
        errors.value = [];
        submitButton.value?.removeAttribute("data-kt-indicator");
        window.scrollTo(0,0);

      }).catch((response) => {

        setTimeout(function(){
          const input = document.getElementsByClassName('is-invalid')[0] as HTMLElement
          input.focus();
        },100)

        errors.value = response.data.errors;

        submitButton.value?.removeAttribute("data-kt-indicator");

      })

    }

    const actions = computed(() => {
      return LotLabelActions;
    })



    const columns = [
      {name : 'action', title : 'Action', sortable : true, sort : null, searchable : false},
      {name : 'statusDate', title : 'Status Date', sortable : true, sort : 'desc', searchable : false},
      {name : 'position', title : 'Position Used', sortable : true, sort : null, searchable : false},
      {name : 'quantity', title : 'Quantity', sortable : true, sort : null, searchable : false},
      {name : 'note', title : 'Other Note', sortable : true, sort : null, searchable : false},
      {name : 'actions', title : 'Delete',sortable : false, sort : null, searchable : false}
    ]

    return {
      store,
      route,
      success,
      errors,
      submitForm,
      submitButton,
      actions,
      columns,
      props,
      moment
    }
  }
}
</script>
