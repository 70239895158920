
import { onMounted, ref, reactive, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

import { Actions as LotLabelActions } from "@/store/enums/LotLabelsEnums.ts";
import { Actions as LabelActions } from "@/store/enums/LabelActionsEnums.ts";
import Table from '@/components/custom/Table.vue';
import DeleteRecord from '@/components/custom/DeleteRecord.vue';
import AddLabelAction from '@/views/lots/composites/labels/AddLabelAction.vue'
import EditLabelAction from '@/views/lots/composites/labels/EditLabelAction.vue'
import moment from 'moment'
export default {
  name : 'CompositeLotLabelControl',
  components : {
    Table,
    DeleteRecord,
    AddLabelAction,
    EditLabelAction
  },
  props : ["id"],
  setup(props){

    const store = useStore();

    const route = useRoute();

    const success = ref(false)

    const errors = reactive({
      value : {}
    });

    const submitButton = ref<HTMLElement | null>(null);

    onMounted(() => {

      store.dispatch(LabelActions.GET_LABEL_ACTIONS);



    })

    const submitForm = () => {

      submitButton.value?.setAttribute("data-kt-indicator", "on");

      store.dispatch(LotLabelActions.UPDATE_LABEL_CONTROL,{id : props.id ,labelsProduced : store.getters.myLotAssembly.labelsProduced}).then(() => {

        success.value = true;
        errors.value = [];
        submitButton.value?.removeAttribute("data-kt-indicator");
        window.scrollTo(0,0);

      }).catch((response) => {

        setTimeout(function(){
          const input = document.getElementsByClassName('is-invalid')[0] as HTMLElement
          input.focus();
        },100)

        errors.value = response.data.errors;

        submitButton.value?.removeAttribute("data-kt-indicator");

      })

    }

    const actions = computed(() => {
      return LotLabelActions;
    })



    const columns = [
      {name : 'action', title : 'Action', sortable : true, sort : null, searchable : false},
      {name : 'statusDate', title : 'Status Date', sortable : true, sort : 'desc', searchable : false},
      {name : 'position', title : 'Position Used', sortable : true, sort : null, searchable : false},
      {name : 'quantity', title : 'Quantity', sortable : true, sort : null, searchable : false},
      {name : 'note', title : 'Other Note', sortable : true, sort : null, searchable : false},
      {name : 'actions', title : 'Delete',sortable : false, sort : null, searchable : false}
    ]

    return {
      store,
      route,
      success,
      errors,
      submitForm,
      submitButton,
      actions,
      columns,
      props,
      moment
    }
  }
}
