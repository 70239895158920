<template>
  <div>
    <!--begin::details View-->
    <div class="card mb-5 mb-xl-10" id="kt_profile_details_view">
      <!--begin::Card header-->
      <div class="card-header cursor-pointer">
        <!--begin::Card title-->
        <div class="card-title m-0">
          <h3 class="fw-bolder m-0">Composite Lot Record</h3>
        </div>
        <!--end::Card title-->
      </div>

      <!--begin::Card body-->
      <div class="card-body p-9">

        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link active" id="details-tab" data-bs-toggle="tab" data-bs-target="#details" type="button" role="tab" aria-controls="details" aria-selected="true">Details</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="activity-tab" data-bs-toggle="tab" data-bs-target="#activity" type="button" role="tab" aria-controls="activity" aria-selected="false">Activity</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="bom-tab" data-bs-toggle="tab" data-bs-target="#bom" type="button" role="tab" aria-controls="bom" aria-selected="false">BOM</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="prod-control-tab" data-bs-toggle="tab" data-bs-target="#prod-control" type="button" role="tab" aria-controls="prod-control" aria-selected="false">Production Control</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="label-control-tab" data-bs-toggle="tab" data-bs-target="#label-control" type="button" role="tab" aria-controls="label-control" aria-selected="false">Label Control</button>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade show active" id="details" role="tabpanel" aria-labelledby="details-tab">
            <CompositeLotDetails :id="route.params.id" />
          </div>
          <div class="tab-pane fade" id="activity" role="tabpanel" aria-labelledby="activity-tab">
            <CompositeLotActivities :id="route.params.id" />
          </div>
          <div class="tab-pane fade" id="bom" role="tabpanel" aria-labelledby="bom-tab">
            <CompositeLotBOM :id="route.params.id" />
          </div>
          <div class="tab-pane fade" id="prod-control" role="tabpanel" aria-labelledby="prod-control-tab">
            <CompositeLotProductionControl :id="route.params.id" />
          </div>
          <div class="tab-pane fade" id="label-control" role="tabpanel" aria-labelledby="label-control-tab">
            <CompositeLotLabelControl :id="route.params.id" />
          </div>
        </div>

      </div>

    </div>

  </div>
</template>
<script lang="ts">
import { onMounted } from 'vue';
import CompositeLotDetails from '@/views/lots/composites/CompositeLotDetails.vue'
import CompositeLotBOM from '@/views/lots/composites/bom/CompositeLotBOM.vue'
import CompositeLotProductionControl from '@/views/lots/composites/production/CompositeLotProductionControl.vue'
import CompositeLotLabelControl from '@/views/lots/composites/labels/CompositeLotLabelControl.vue'
import CompositeLotActivities from '@/views/lots/composites/CompositeLotActivities.vue'
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Actions as StoreActions } from "@/store/enums/StoreEnums.ts";
import { Actions } from "@/store/enums/LotEnums.ts";
import { Actions as LotAssemblyActions } from "@/store/enums/LotAssembleComponentsEnums.ts";
export default {
  name: 'CompositeLotEdit',
  components : {
    CompositeLotDetails,
    CompositeLotBOM,
    CompositeLotProductionControl,
    CompositeLotLabelControl,
    CompositeLotActivities
  },
  setup(){
    const route = useRoute();
    const store = useStore();

    store.dispatch(StoreActions.ADD_BODY_CLASSNAME,'page-loading')

    onMounted(() => {
      setCurrentPageBreadcrumbs("Lots",[
        {title : "Lots",to : "/lots/list"},
        {title : "Composite Lot Record",to : "/lots/composite/edit/"+route.params.id}
      ]);

      store.dispatch(LotAssemblyActions.GET_LOT_ASSEMBLY,{lot_id : route.params.id})

      store.dispatch(Actions.GET_LOT_DETAILS,{id : route.params.id}).then(() => {

        store.dispatch(StoreActions.REMOVE_BODY_CLASSNAME,'page-loading')

      });
    });

    return {
      route
    }
  }
}
</script>
