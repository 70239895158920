
import { onMounted } from 'vue';
import CompositeLotDetails from '@/views/lots/composites/CompositeLotDetails.vue'
import CompositeLotBOM from '@/views/lots/composites/bom/CompositeLotBOM.vue'
import CompositeLotProductionControl from '@/views/lots/composites/production/CompositeLotProductionControl.vue'
import CompositeLotLabelControl from '@/views/lots/composites/labels/CompositeLotLabelControl.vue'
import CompositeLotActivities from '@/views/lots/composites/CompositeLotActivities.vue'
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Actions as StoreActions } from "@/store/enums/StoreEnums.ts";
import { Actions } from "@/store/enums/LotEnums.ts";
import { Actions as LotAssemblyActions } from "@/store/enums/LotAssembleComponentsEnums.ts";
export default {
  name: 'CompositeLotEdit',
  components : {
    CompositeLotDetails,
    CompositeLotBOM,
    CompositeLotProductionControl,
    CompositeLotLabelControl,
    CompositeLotActivities
  },
  setup(){
    const route = useRoute();
    const store = useStore();

    store.dispatch(StoreActions.ADD_BODY_CLASSNAME,'page-loading')

    onMounted(() => {
      setCurrentPageBreadcrumbs("Lots",[
        {title : "Lots",to : "/lots/list"},
        {title : "Composite Lot Record",to : "/lots/composite/edit/"+route.params.id}
      ]);

      store.dispatch(LotAssemblyActions.GET_LOT_ASSEMBLY,{lot_id : route.params.id})

      store.dispatch(Actions.GET_LOT_DETAILS,{id : route.params.id}).then(() => {

        store.dispatch(StoreActions.REMOVE_BODY_CLASSNAME,'page-loading')

      });
    });

    return {
      route
    }
  }
}
