<template>
  <div>
  <!-- Button trigger modal -->
  <button type="button" class="btn btn-success btn-sm" data-bs-toggle="modal" data-bs-target="#exampleModalAction">
    Add Action
  </button>
  <!-- Modal -->
  <div class="modal fade" id="exampleModalAction" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Add Production Action</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row mb-3" v-show="success">
            <div class="col-12">
              <div class="alert alert-success" role="alert">
                Successfully added action!
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-12">
              <label class="col-form-label required fw-bold fs-6">Action</label>
              <select class="form-select" :class="{'is-invalid' : errors.value && errors.value.production_action_type_id}" v-model="formData.production_action_type_id">
                <option selected disabled value="null">Select Action</option>
                <option :value="item.id" v-for="(item,key) in store.getters.myProductionActions" :key="key">{{item.name}}</option>
              </select>
              <span class="text-danger" v-if="errors.value && errors.value.production_action_type_id">{{errors.value && errors.value.production_action_type_id[0]}}</span>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-12 col-lg-6 mb-3 mb-lg-0">
              <label class="col-form-label required fw-bold fs-6">Start Time</label>
              <input type="datetime-local" class="form-control" :class="{'is-invalid' : errors.value && errors.value.startTime}" v-model="formData.startTime">
              <span class="text-danger" v-if="errors.value && errors.value.startTime">{{errors.value && errors.value.startTime[0]}}</span>
            </div>
            <div class="col-12 col-lg-6">
              <label class="col-form-label required fw-bold fs-6">End Time</label>
              <input type="datetime-local" class="form-control" :class="{'is-invalid' : errors.value && errors.value.endTime}" v-model="formData.endTime">
              <span class="text-danger" v-if="errors.value && errors.value.endTime">{{errors.value && errors.value.endTime[0]}}</span>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-12">
              <label class="col-form-label required fw-bold fs-6">Operator</label>
              <select class="form-select" :class="{'is-invalid' : errors.value && errors.value.user_id}" v-model="formData.user_id">
                <option selected disabled value="null">Select Operator</option>
                <option :value="item.id" v-for="(item,key) in store.getters.myUsers" :key="key">{{item.first_name}} {{item.last_name}}</option>
              </select>
              <span class="text-danger" v-if="errors.value && errors.value.user_id">{{errors.value && errors.value.user_id[0]}}</span>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-12 col-lg-6 mb-3 mb-lg-0">
              <label class="col-form-label required fw-bold fs-6">Quantity</label>
              <input type="number" class="form-control" :class="{'is-invalid' : errors.value && errors.value.quantity}" v-model="formData.quantity">
              <span class="text-danger" v-if="errors.value && errors.value.quantity">{{errors.value && errors.value.quantity[0]}}</span>
            </div>
            <div class="col-12 col-lg-6">
              <label class="col-form-label required fw-bold fs-6">Carton Quantity</label>
              <input type="number" class="form-control" :class="{'is-invalid' : errors.value && errors.value.cartonQuantity}" v-model="formData.cartonQuantity">
              <span class="text-danger" v-if="errors.value && errors.value.cartonQuantity">{{errors.value && errors.value.cartonQuantity[0]}}</span>
            </div>
          </div>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id="close-modal-action">Close</button>
          <button
            type="submit"
            id="addActionSubmitBtn"
            class="btn btn-primary fw-bolder"
            @click="submitForm();"
          >
            <span class="indicator-label">
              Save
            </span>

            <span class="indicator-progress">
              Please wait...
              <span
                class="spinner-border spinner-border-sm align-middle ms-2"
              ></span>
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script lang="ts">
import { Actions } from "@/store/enums/LotProductionEnums.ts";
import { useStore } from 'vuex'
import { ref, reactive, onMounted } from 'vue'
import Swal from "sweetalert2/dist/sweetalert2.min.js";
 export default {
   name : 'AddProductionAction',
   props : ["id"],
   emits: ['input'],
   setup(props, { emit }){
     const store = useStore();

     const success = ref(false)
     const errors = reactive({
       value : {}
     });


     const formData = reactive({
       lot_id : props.id,
       production_action_type_id : null,
       startTime : '',
       endTime : '',
       user_id : JSON.parse(JSON.stringify(store.getters.currentUser.id)),
       quantity : 1,
       cartonQuantity : 1
     })

     onMounted(() => {


       const myModalEl = document.getElementById('exampleModalAction') as any
       myModalEl.addEventListener('hidden.bs.modal', function () {
         success.value = false;
         errors.value = [];
         formData.production_action_type_id = null;
         formData.startTime = '';
         formData.endTime = '';
         formData.user_id = JSON.parse(JSON.stringify(store.getters.currentUser.id));
         formData.quantity = 1;
         formData.cartonQuantity = 1;
       })
     })


     const submitForm = () => {
       const submitButton = ref<HTMLElement | null>(document.getElementById('addActionSubmitBtn') as HTMLElement);

       if(submitButton.value){
         submitButton.value.setAttribute("data-kt-indicator", "on");
       }

       store.dispatch(Actions.ADD_LOT_PRODUCTION_ACTION,formData).then((r) => {

         submitButton.value?.removeAttribute("data-kt-indicator");

           errors.value = [];
           success.value = true;

           setTimeout(function(){

             const closeModal = document.getElementById('close-modal-action') as HTMLElement;
             closeModal.click();
             emit('input');

           },1000)


       }).catch((response) => {
         setTimeout(function(){
           const input = document.getElementsByClassName('is-invalid')[0] as HTMLElement
           input.focus();
         },100)

         errors.value = response.data.errors;

         submitButton.value?.removeAttribute("data-kt-indicator");

       })
     }

     return {
       props,
       formData,
       errors,
       store,
       submitForm,
       success
     }
   }
 }
</script>
