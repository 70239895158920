
import { Actions } from "@/store/enums/LotLabelsEnums.ts";
import { useStore } from 'vuex'
import { ref, reactive, onMounted } from 'vue'
import Swal from "sweetalert2/dist/sweetalert2.min.js";
 export default {
   name : 'AddLabelAction',
   props : ["id"],
   emits: ['input'],
   setup(props, { emit }){
     const store = useStore();

     const success = ref(false)
     const errors = reactive({
       value : {}
     });


     const formData = reactive({
       lot_id : props.id,
       label_action_type_id : null,
       statusDate : '',
       position : '',
       quantity : 1,
       note : ''
     })

     onMounted(() => {


       const myModalEl = document.getElementById('exampleModalLabelAction') as any
       myModalEl.addEventListener('hidden.bs.modal', function () {
         success.value = false;
         errors.value = [];
         formData.label_action_type_id = null;
         formData.statusDate = '';
         formData.position = '';
         formData.quantity = 1;
         formData.note = '';
       })
     })


     const submitForm = () => {
       const submitButton = ref<HTMLElement | null>(document.getElementById('addLabelActionSubmitBtn') as HTMLElement);

       if(submitButton.value){
         submitButton.value.setAttribute("data-kt-indicator", "on");
       }

       store.dispatch(Actions.ADD_LOT_LABEL_ACTION,formData).then((r) => {

         submitButton.value?.removeAttribute("data-kt-indicator");

           errors.value = [];
           success.value = true;

           setTimeout(function(){

             const closeModal = document.getElementById('close-modal-label-action') as HTMLElement;
             closeModal.click();
             emit('input');

           },1000)


       }).catch((response) => {
         setTimeout(function(){
           const input = document.getElementsByClassName('is-invalid')[0] as HTMLElement
           input.focus();
         },100)

         errors.value = response.data.errors;

         submitButton.value?.removeAttribute("data-kt-indicator");

       })
     }

     return {
       props,
       formData,
       errors,
       store,
       submitForm,
       success
     }
   }
 }
