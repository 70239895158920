
import { Actions } from "@/store/enums/LotProductionEnums.ts";
import { useStore } from 'vuex'
import { ref, reactive, onMounted } from 'vue'
import Swal from "sweetalert2/dist/sweetalert2.min.js";
 export default {
   name : 'AddProductionAction',
   props : ["id"],
   emits: ['input'],
   setup(props, { emit }){
     const store = useStore();

     const success = ref(false)
     const errors = reactive({
       value : {}
     });


     const formData = reactive({
       lot_id : props.id,
       production_action_type_id : null,
       startTime : '',
       endTime : '',
       user_id : JSON.parse(JSON.stringify(store.getters.currentUser.id)),
       quantity : 1,
       cartonQuantity : 1
     })

     onMounted(() => {


       const myModalEl = document.getElementById('exampleModalAction') as any
       myModalEl.addEventListener('hidden.bs.modal', function () {
         success.value = false;
         errors.value = [];
         formData.production_action_type_id = null;
         formData.startTime = '';
         formData.endTime = '';
         formData.user_id = JSON.parse(JSON.stringify(store.getters.currentUser.id));
         formData.quantity = 1;
         formData.cartonQuantity = 1;
       })
     })


     const submitForm = () => {
       const submitButton = ref<HTMLElement | null>(document.getElementById('addActionSubmitBtn') as HTMLElement);

       if(submitButton.value){
         submitButton.value.setAttribute("data-kt-indicator", "on");
       }

       store.dispatch(Actions.ADD_LOT_PRODUCTION_ACTION,formData).then((r) => {

         submitButton.value?.removeAttribute("data-kt-indicator");

           errors.value = [];
           success.value = true;

           setTimeout(function(){

             const closeModal = document.getElementById('close-modal-action') as HTMLElement;
             closeModal.click();
             emit('input');

           },1000)


       }).catch((response) => {
         setTimeout(function(){
           const input = document.getElementsByClassName('is-invalid')[0] as HTMLElement
           input.focus();
         },100)

         errors.value = response.data.errors;

         submitButton.value?.removeAttribute("data-kt-indicator");

       })
     }

     return {
       props,
       formData,
       errors,
       store,
       submitForm,
       success
     }
   }
 }
