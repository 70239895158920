
import { Actions } from "@/store/enums/LotBomEnums.ts";
import { useStore } from 'vuex'
import { ref, reactive, onMounted, getCurrentInstance } from 'vue'
import Swal from "sweetalert2/dist/sweetalert2.min.js";
 export default {
   name : 'AddLotBom',
   props : {
     bom : {
       type : [Array,Object]
     },
   },
   emits: ['input'],
   setup(props, { emit }){
     const store = useStore();

     const id = getCurrentInstance() as any;

     const myBOM = reactive(JSON.parse(JSON.stringify({ value : props.bom})));

     const success = ref(false)

     const errors = reactive({
       value : {}
     });

     onMounted(() => {

       const myModalEl = document.getElementById('exampleModal'+id.uid) as HTMLElement;

       myModalEl.addEventListener('hidden.bs.modal', function () {
         success.value = false;
         myBOM.value = JSON.parse(JSON.stringify(props.bom))
       })
     })


     const submitForm = () => {
       const submitButton = ref<HTMLElement | null>(document.getElementById('submitButton'+id.uid) as HTMLElement);

       if(submitButton.value){
         submitButton.value.setAttribute("data-kt-indicator", "on");
       }

       store.dispatch(Actions.UPDATE_LOT_BOM,myBOM.value).then((r) => {

         submitButton.value?.removeAttribute("data-kt-indicator");

         if(r.success){
           errors.value = [];
           success.value = true;

           setTimeout(function(){
             submitButton.value?.removeAttribute("data-kt-indicator");
             const closeModal = document.getElementById('close-modal'+id.uid) as HTMLElement;
             closeModal.click();
             emit('input')
           },1000)

         }else{
           Swal.fire({
             text: r.message,
             icon: "error",
             buttonsStyling: false,
             confirmButtonText: "Close",
             customClass: {
               confirmButton: "btn fw-bold btn-light-danger"
             }
           });
         }

       }).catch((response) => {
         setTimeout(function(){
           const input = document.getElementsByClassName('is-invalid')[0] as HTMLElement
           input.focus();
         },100)

         errors.value = response.data.errors;

         submitButton.value?.removeAttribute("data-kt-indicator");

       })
     }

     return {
       props,
       errors,
       store,
       submitForm,
       success,
       id,
       myBOM
     }
   }
 }
