
import { Actions } from "@/store/enums/LotBomEnums.ts";
import { useStore } from 'vuex'
import { ref, reactive, onMounted, computed } from 'vue'
import Swal from "sweetalert2/dist/sweetalert2.min.js";
 export default {
   name : 'AddLotBom',
   props : ["id"],
   emits: ['input'],
   setup(props, { emit }){
     const store = useStore();

     const success = ref(false)
     const errors = reactive({
       value : {}
     });


     const formData = reactive({
       parent_lot_id : props.id,
       lot_id : null,
       quantity : null
     })

     onMounted(() => {

       const myModalEl = document.getElementById('exampleModalBOM') as any
       myModalEl.addEventListener('hidden.bs.modal', function () {
         success.value = false;
         errors.value = [];
         formData.parent_lot_id = props.id;
         formData.lot_id = null;
         formData.quantity = null;
       })
     })

     const componentLotsOnStock = computed(() => {
       const lots : string[] = [];
       store.getters.mycomponentLotsOnStock.forEach((item) => {
         if(Number(item.inStock) > 0){
           lots.push(item)
         }
       });
       return lots;
     })

     const submitForm = () => {
       const submitButton = ref<HTMLElement | null>(document.getElementById('addBOMSubmitBtn') as HTMLElement);

       if(submitButton.value){
         submitButton.value.setAttribute("data-kt-indicator", "on");
       }

       store.dispatch(Actions.ADD_LOT_BOM,formData).then((r) => {

         submitButton.value?.removeAttribute("data-kt-indicator");

         if(r.success){
           errors.value = [];
           success.value = true;

           setTimeout(function(){

             const closeModal = document.getElementById('close-modal-bom') as HTMLElement;
             closeModal.click();
             emit('input');

           },1000)
         }else{
           Swal.fire({
             text: r.message,
             icon: "error",
             buttonsStyling: false,
             confirmButtonText: "Close",
             customClass: {
               confirmButton: "btn fw-bold btn-light-danger"
             }
           });
         }

       }).catch((response) => {
         setTimeout(function(){
           const input = document.getElementsByClassName('is-invalid')[0] as HTMLElement
           input.focus();
         },100)

         errors.value = response.data.errors;

         submitButton.value?.removeAttribute("data-kt-indicator");

       })
     }

     return {
       props,
       formData,
       errors,
       store,
       submitForm,
       componentLotsOnStock,
       success
     }
   }
 }
