<template>
  <div class="pt-10">
    <Table :columns="columns" ref="table" :action="actions.GET_LOT_BOMS" :id="Number(props.id)" :searchEnabled="false" :showPerPageEnabled="false">


      <template v-slot:extra="slotProps">

        <div class="mb-5">

            <AddLotBom :id="props.id" @input="slotProps.search();getAvailableComponentLots()"/>

        </div>

      </template>


      <template v-slot:actions="slotProps">

        <div>

          <div class="btn-group" role="group">

            <EditLotBom :bom="slotProps.row" @input="slotProps.search();getAvailableComponentLots();"/>
            <DeleteRecord :id="slotProps.row.id" :action="actions.DELETE_LOT_BOM" @input="getAvailableComponentLots()" :callback="slotProps.search"/>

          </div>

        </div>

      </template>

    </Table>
  </div>
</template>
<script>
import { Actions } from "@/store/enums/LotBomEnums";
import Table from '@/components/custom/Table.vue';
import DeleteRecord from '@/components/custom/DeleteRecord.vue';
import AddLotBom from "@/views/lots/composites/bom/AddLotBom.vue";
import EditLotBom from "@/views/lots/composites/bom/EditLotBom.vue";
import { Actions as LotActions } from "@/store/enums/LotEnums.ts";
import { useStore } from 'vuex'
import { computed, ref, reactive, onMounted } from 'vue'
export default {
  name : 'CompositeLotBOM',
  props : ["id"],
  components : {
    Table,
    DeleteRecord,
    AddLotBom,
    EditLotBom
  },
  setup(props){

    const store = useStore();

    const getAvailableComponentLots = () => {
      store.dispatch(LotActions.GET_COMPONENT_LOTS_ON_STOCK);
    }

    onMounted(() => {
      getAvailableComponentLots()
    });

    const actions = computed(() => {
      return Actions;
    })



    const columns = [
      {name : 'code', title : 'Product Code', sortable : true, sort : null, searchable : false},
      {name : 'lotNumber', title : 'Lot Number', sortable : true, sort : 'asc', searchable : false},
      {name : 'name', title : 'Product Name', sortable : true, sort : null, searchable : false},
      {name : 'quantity', title : 'Quantity', sortable : true, sort : null, searchable : false},
      {name : 'actions', title : 'Delete',sortable : false, sort : null, searchable : false}
    ];

    return {
      actions,
      props,
      columns,
      store,
      getAvailableComponentLots
    }
  }
}
</script>
