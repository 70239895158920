<template>
  <div class="pt-10">
    <div class="mb-10 pb-5 border-bottom fw-500 fs-1-1 d-flex">
      <div class="me-10">
        Dispatched to Consumer : {{Number(store.getters.myLotCompositeTotals ? Number(store.getters.myLotCompositeTotals.dispatched) : 0)}}
      </div>
      <div class="me-10">
        In Clonallon Stock : {{Number(store.getters.myLotCompositeTotals ? Number(store.getters.myLotCompositeTotals.inStock) : 0)}}
      </div>
      <div class="me-10">
        In Customer Stock : {{Number(store.getters.myLotCompositeTotals ? Number(store.getters.myLotCompositeTotals.inCustomerStock) : 0)}}
      </div>
    </div>
    <Table :columns="columns" ref="table" :action="actions.GET_LOT_COMPOSITE_ACTIVITIES" :id="Number(props.id)">

      <template v-slot:activityType="slotProps">

        <div class="d-flex" v-html="generateActivityType(slotProps.row.activityType)"></div>

      </template>

      <template v-slot:activityDate="slotProps">

        <div>{{moment(new Date(slotProps.row.activityDate)).format('DD MMM YYYY')}}</div>

      </template>

    </Table>
  </div>
</template>
<script lang="ts">
import { onMounted, computed } from 'vue'
import { useStore } from "vuex";
import { Actions } from "@/store/enums/ActivitiesEnums.ts";
import { useRouter } from "vue-router";
import Table from '@/components/custom/Table.vue';
import moment from 'moment'
export default {
  name : 'CompositeLotActivities',
  components : {
    Table
  },
  props : ["id"],
  setup(props){

    const store = useStore();
    const router = useRouter();

    onMounted(() => {
      store.dispatch(Actions.GET_LOT_COMPOSITE_TOTALS,{id : props.id});
    });

    const actions = computed(() => {
      return Actions;
    })

    const columns = [
      {name : 'activityType', title : 'Activity Type', sortable : true, sort : null, searchable : true},
      {name : 'activityDate', title : 'Activity Date', sortable : true, sort : 'asc', searchable : true},
      {name : 'quantity', title : 'Quantity',sortable : false, sort : null, searchable : false},
    ];

    const generateActivityType = (activityType) => {

      let str = '';

      switch(activityType){

        case 'manufactured':

        str = "<i class='fs-1-5 la la-box me-3'></i> Manufactured";

        break;

        case 'orderedFromConsumer':

        str = "<i class='fs-1-5 la la-shopping-cart me-3'></i> Ordered From Consumer";

        break;

        case 'dispatchedToConsumer':

        str = "<i class='fs-1-5 la la-truck-moving me-3'></i> Dispatched To Consumer";

        break;

        case 'invoicedToCustomer':

        str = "<i class='fs-1-5 la la-clipboard-list me-3'></i> Invoiced To Customer";

        break;

        case 'adjusted':

        str = "<i class='fs-1-5 la la-edit me-3'></i> Adjusted";

        break;

      }

      return str;

    }

    return {
      columns,
      store,
      router,
      Table,
      props,
      actions,
      generateActivityType,
      moment
    }
  }
}
</script>
