<template>
  <div>
  <!-- Button trigger modal -->
  <button type="button" class="btn btn-success btn-sm" data-bs-toggle="modal" data-bs-target="#exampleModalLabelAction">
    Add Action
  </button>
  <!-- Modal -->
  <div class="modal fade" id="exampleModalLabelAction" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Add Label Action</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row mb-3" v-show="success">
            <div class="col-12">
              <div class="alert alert-success" role="alert">
                Successfully added action!
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-12">
              <label class="col-form-label required fw-bold fs-6">Action</label>
              <select class="form-select" :class="{'is-invalid' : errors.value && errors.value.label_action_type_id}" v-model="formData.label_action_type_id">
                <option selected disabled value="null">Select Action</option>
                <option :value="item.id" v-for="(item,key) in store.getters.myLabelActions" :key="key">{{item.name}}</option>
              </select>
              <span class="text-danger" v-if="errors.value && errors.value.label_action_type_id">{{errors.value && errors.value.label_action_type_id[0]}}</span>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-12">
              <label class="col-form-label required fw-bold fs-6">Status Date</label>
              <input type="date" class="form-control" :class="{'is-invalid' : errors.value && errors.value.statusDate}" v-model="formData.statusDate">
              <span class="text-danger" v-if="errors.value && errors.value.statusDate">{{errors.value && errors.value.statusDate[0]}}</span>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-12">
              <label class="col-form-label required fw-bold fs-6">Position used</label>
              <input type="text" class="form-control" :class="{'is-invalid' : errors.value && errors.value.position}" v-model="formData.position">
              <span class="text-danger" v-if="errors.value && errors.value.position">{{errors.value && errors.value.position[0]}}</span>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-12">
              <label class="col-form-label required fw-bold fs-6">Quantity</label>
              <input type="number" class="form-control" :class="{'is-invalid' : errors.value && errors.value.quantity}" v-model="formData.quantity">
              <span class="text-danger" v-if="errors.value && errors.value.quantity">{{errors.value && errors.value.quantity[0]}}</span>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-12">
              <label class="col-form-label fw-bold fs-6">Other Note</label>
              <input type="text" class="form-control" :class="{'is-invalid' : errors.value && errors.value.note}" v-model="formData.note">
              <span class="text-danger" v-if="errors.value && errors.value.note">{{errors.value && errors.value.note[0]}}</span>
            </div>
          </div>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id="close-modal-label-action">Close</button>
          <button
            type="submit"
            id="addLabelActionSubmitBtn"
            class="btn btn-primary fw-bolder"
            @click="submitForm();"
          >
            <span class="indicator-label">
              Save
            </span>

            <span class="indicator-progress">
              Please wait...
              <span
                class="spinner-border spinner-border-sm align-middle ms-2"
              ></span>
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script lang="ts">
import { Actions } from "@/store/enums/LotLabelsEnums.ts";
import { useStore } from 'vuex'
import { ref, reactive, onMounted } from 'vue'
import Swal from "sweetalert2/dist/sweetalert2.min.js";
 export default {
   name : 'AddLabelAction',
   props : ["id"],
   emits: ['input'],
   setup(props, { emit }){
     const store = useStore();

     const success = ref(false)
     const errors = reactive({
       value : {}
     });


     const formData = reactive({
       lot_id : props.id,
       label_action_type_id : null,
       statusDate : '',
       position : '',
       quantity : 1,
       note : ''
     })

     onMounted(() => {


       const myModalEl = document.getElementById('exampleModalLabelAction') as any
       myModalEl.addEventListener('hidden.bs.modal', function () {
         success.value = false;
         errors.value = [];
         formData.label_action_type_id = null;
         formData.statusDate = '';
         formData.position = '';
         formData.quantity = 1;
         formData.note = '';
       })
     })


     const submitForm = () => {
       const submitButton = ref<HTMLElement | null>(document.getElementById('addLabelActionSubmitBtn') as HTMLElement);

       if(submitButton.value){
         submitButton.value.setAttribute("data-kt-indicator", "on");
       }

       store.dispatch(Actions.ADD_LOT_LABEL_ACTION,formData).then((r) => {

         submitButton.value?.removeAttribute("data-kt-indicator");

           errors.value = [];
           success.value = true;

           setTimeout(function(){

             const closeModal = document.getElementById('close-modal-label-action') as HTMLElement;
             closeModal.click();
             emit('input');

           },1000)


       }).catch((response) => {
         setTimeout(function(){
           const input = document.getElementsByClassName('is-invalid')[0] as HTMLElement
           input.focus();
         },100)

         errors.value = response.data.errors;

         submitButton.value?.removeAttribute("data-kt-indicator");

       })
     }

     return {
       props,
       formData,
       errors,
       store,
       submitForm,
       success
     }
   }
 }
</script>
