
import { onMounted, ref, reactive, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { Actions as LotProductionActions } from "@/store/enums/LotProductionEnums.ts";
import { Actions as ProductionActions } from "@/store/enums/ProductionActionsEnums.ts";
import {Actions as UserActions} from '@/store/enums/UserEnums.ts';
import Table from '@/components/custom/Table.vue';
import DeleteRecord from '@/components/custom/DeleteRecord.vue';
import AddProductionAction from '@/views/lots/composites/production/AddProductionAction.vue'
import EditProductionAction from '@/views/lots/composites/production/EditProductionAction.vue'
import moment from 'moment'
export default {
  name : 'CompositeLotProductionControl',
  components : {
    Table,
    DeleteRecord,
    AddProductionAction,
    EditProductionAction
  },
  props : ["id"],
  setup(props){

    const store = useStore();

    const route = useRoute();

    const success = ref(false)

    const errors = reactive({
      value : {}
    });

    const submitButton = ref<HTMLElement | null>(null);

    onMounted(() => {

      store.dispatch(ProductionActions.GET_PRODUCTION_ACTIONS);

      store.dispatch(UserActions.GET_USERS);

    })

    const submitForm = () => {

      submitButton.value?.setAttribute("data-kt-indicator", "on");

      store.dispatch(LotProductionActions.UPDATE_PRODUCTION_CONTROL,store.getters.myLotAssembly).then(() => {

        success.value = true;
        errors.value = [];
        submitButton.value?.removeAttribute("data-kt-indicator");
        window.scrollTo(0,0);

      }).catch((response) => {

        setTimeout(function(){
          const input = document.getElementsByClassName('is-invalid')[0] as HTMLElement
          input.focus();
        },100)

        errors.value = response.data.errors;

        submitButton.value?.removeAttribute("data-kt-indicator");

      })

    }

    const actions = computed(() => {
      return LotProductionActions;
    })



    const columns = [
      {name : 'action', title : 'Action', sortable : true, sort : null, searchable : false},
      {name : 'startTime', title : 'Start Time', sortable : true, sort : 'desc', searchable : false},
      {name : 'endTime', title : 'End Time', sortable : true, sort : null, searchable : false},
      {name : 'operator', title : 'Operator', sortable : true, sort : null, searchable : false},
      {name : 'quantity', title : 'Quantity', sortable : true, sort : null, searchable : false},
      {name : 'cartonQuantity', title : 'Carton Quantity', sortable : true, sort : null, searchable : false},
      {name : 'actions', title : 'Delete',sortable : false, sort : null, searchable : false}
    ]

    return {
      store,
      route,
      success,
      errors,
      submitForm,
      submitButton,
      actions,
      columns,
      props,
      moment
    }
  }
}
