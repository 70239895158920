
import { Actions } from "@/store/enums/LotProductionEnums.ts";
import { useStore } from 'vuex'
import { ref, reactive, onMounted, getCurrentInstance } from 'vue'
import Swal from "sweetalert2/dist/sweetalert2.min.js";
 export default {
   name : 'EditProductionAction',
   props : {
     productionAction : {
       type : [Array,Object]
     },
   },
   emits: ['input'],
   setup(props, { emit }){
     const store = useStore();

     const id = getCurrentInstance() as any;

     const myProductionAction = reactive(JSON.parse(JSON.stringify({ value : props.productionAction})));

     const success = ref(false)

     const errors = reactive({
       value : {}
     });


     onMounted(() => {

       const myModalEl = document.getElementById('exampleModal'+id.uid) as HTMLElement;

       myModalEl.addEventListener('hidden.bs.modal', function () {
         success.value = false;
         myProductionAction.value = JSON.parse(JSON.stringify(props.productionAction))
       })

     })


     const submitForm = () => {

       const submitButton = ref<HTMLElement | null>(document.getElementById('submitButton'+id.uid) as HTMLElement);

       submitButton.value?.setAttribute("data-kt-indicator", "on");

       store.dispatch(Actions.UPDATE_LOT_PRODUCTION_ACTION,myProductionAction.value).then((r) => {

         submitButton.value?.removeAttribute("data-kt-indicator");

           errors.value = [];
           success.value = true;

           setTimeout(function(){

             const closeModal = document.getElementById('close-modal'+id.uid) as HTMLElement;

             closeModal.click();
             emit('input');

           },1000)


       }).catch((response) => {
         setTimeout(function(){
           const input = document.getElementsByClassName('is-invalid')[0] as HTMLElement
           input.focus();
         },100)

         errors.value = response.data.errors;

         submitButton.value?.removeAttribute("data-kt-indicator");

       })
     }

     return {
       props,
       myProductionAction,
       errors,
       store,
       submitForm,
       success,
       id
     }
   }
 }
